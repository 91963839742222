export const CXI_constants = Object.freeze({
  FAMILY_TYPE_EQUIPMENT: 1,
  FAMILY_TYPE_PUMP: 2,
  FAMILY_TYPE_PART: 3,
  IEQ_COMPANY_ID: 1,
  RFQ: 'Quote Draft',
  SFQ: 'Submitted For Quote',
  PCR: 'Processing',
  RQP: 'RFQ Processed',
  USER_PUMP: 'userPump',
  UPGRADE_LINK: 'https://www.ieq.cx/contact-cxi/',
  TRIAL_USER_TYPE: 4,
  FIELD_TYPES: [
    {
      id: 1,
      name: 'Text',
      description: 'For example: Make, Model, Serial No, etc.'
    },
    {
      id: 2,
      name: 'Number',
      description: 'For example: Mileage, Hrs Ran, etc.'
    },
    {
      id: 3,
      name: 'Description',
      description: 'For example: Initial Cost, etc.'
    },
    {
      id: 4,
      name: 'Pictures',
      description: 'For example: Equip Pictures, Troubleshooting Charts, etc.'
    },
    {
      id: 5,
      name: 'Documents',
      description: 'For example: PDFs, Manuals, Contracts, etc.'
    }
  ]
})

export const PUMP_DDL = Object.freeze({
  status_list: [
    { id: 1, value: 'Online' },
    { id: 2, value: 'Offline' },
    { id: 3, value: 'Under Repair' },
    { id: 4, value: 'Waiting For Materials' }
  ],
  power_source: [
    { id: 1, value: 'Motor' },
    { id: 2, value: 'Engine' },
    { id: 3, value: 'Custom' }
  ],
  fluid_end_connections: [],
  // fluid_end_connections_piston: [
  //   { id: 5, value: 'FLANGE' },
  //   { id: 6, value: 'FLANGE, DISCHARGE' },
  //   { id: 7, value: 'FLANGE, SUCTION' }
  // ],
  fluid_end_body_materials: [
    { id: 4, value: 'DUCTILE IRON' },
    { id: 2, value: 'STEEL' }
  ],
  plunger_materials_piston: [
    { id: 57, value: 'CAST IRON' },
    { id: 2, value: 'STEEL' },
    { id: 1, value: 'BRONZE (OBSOLETE)' }
  ],
  ring_materials: [
    { id: 61, value: 'CHROME' },
    { id: 60, value: 'BAKELITE (CAST IRON)' },
    { id: 62, value: 'BAKELITE (BRONZE)' }
  ],
  freq_drive_types: [
    { id: 1, value: 'VFD' },
    { id: 2, value: 'Manual' }
  ],
  drive_types: [
    { id: 1, value: 'V-Belt' },
    { id: 2, value: 'Gear' }
  ],
  skid_data: [
    { id: 1, value: 'Dimensions' },
    { id: 2, value: 'Skid Drawing' }
  ],
  additional_components: [
    { id: 1, value: 'Murphy Vibration Switch' },
    { id: 2, value: 'Oil level gauge' },
    { id: 3, value: 'Pump Lubricator' },
    { id: 4, value: 'Vibration Transmitter' },
    { id: 5, value: 'Other gauges' },
    { id: 6, value: 'Pressure Relief Valve' },
    { id: 7, value: 'Dampeners' }
  ],
  packing_size: [
    { id: 1, name: '1-1/8"' },
    { id: 2, name: '1-1/2"' }
  ],
  Piston_Pump_Valve_Types: [
    { value: 1, label: 'Wing Guided' },
    { value: 2, label: 'Mission Slush' }
  ],

  Plunger_Pump_Valve_Types: [
    { value: 3, label: 'Spherical' },
    { value: 4, label: 'Severe Duty Spherical' },
    { value: 5, label: 'Delrin Plate' },
    { value: 6, label: 'Titanium Plate' },
    { value: 7, label: 'Abrasion Resistant' }
  ]
})
